
@import "./constants.scss";

$slate-gray: #738290ff;
$header-color: #697fa5;

$wild-blue-yonder: #a1b5d8ff;
$white: #fffcf7ff;
$caribbean-green: #26c485ff;
$indian-red: #da5552ff;
$purple: #7f30d6;

$button: #6b8ac2;
$error: #da5552ff;
$good: #26c485ff;

$dark-text-color: #001B2E;
$med-text-color: #68848f;

$footer: #222222;