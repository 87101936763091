
@import "./colors.scss";

header {
    display: flex;
    width: 100%;
    height: 55px;
    background-color: $header-color;
    box-shadow: 5px 0px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;

    &.mobile {
        position: relative;
    }

    .logo {
        display: flex;
        width: 300px;
        height: 90%;
        margin: auto 1rem;
        cursor: pointer;

        .lazy-load-image {
            min-width: initial;
            min-height: initial;
            background-color: inherit;
        }

        .text {
            display: flex;
            flex-direction: column;
            margin: auto 0;

            span {
                font-size: 1.6rem;
                margin: auto 0.5rem;
                font-weight: bold;
                color: $purple;
                width: 100%;

                &.green {
                    color: #30ed77;
                    margin: 0.2rem;
                }

                &.bottom {
                    font-size: 1.1rem;
                    color: #65c09d;
                }
            }
        }

        @media screen and (max-width: 390px) {
            width: 280px;

            .text span {
                margin: auto 5px;
                font-size: 1.3rem;

                &.bottom {
                    font-size: 0.9rem;
                }
            }
        }
    }

    button {
        background-color: $button;
        color: $white;
        margin: auto 0.5rem;
    }

    .hamburger {
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        margin: auto 1rem auto auto;

        .line {
            position: absolute;
            width: 40px;
            height: 5px;
            background-color: $white;
            transition: opacity 0.1s ease-in-out 0.25s, transform 0.35s ease-in-out;

            &.one {
                top: 16px;
            }

            &.two {
                top: 26px;
            }

            &.three {
                top: 36px;
            }
        }

        &.open {
            .line {
                transition: opacity 0.2s ease-in-out, transform 0.35s ease-in-out;

                &.one {
                    transform: rotate(45deg) translate(6px, 7px);
                }

                &.two {
                    opacity: 0;
                }

                &.three {
                    transform: rotate(-45deg) translate(7px, -8px);
                }
            }
        }

        &:hover .line {
            background-color: darken(#fff, 6);
        }
    }

    @media screen and (max-width: 1450px) {
        button {
            padding: 0.6rem 1rem;
        }
    }
}

.user.anonymous.mobile {
    height: 50px;
    padding: 0 1rem;
}

.menu {
    display: flex;
    flex-direction: column;
    background-color: lighten($wild-blue-yonder, 10);
    z-index: 5000;
    transition: all 0.3s ease-in-out;
    height: 0px;
    pointer-events: none;

    button {
        margin: auto !important;
        width: 200px;
        background-color: inherit;
        font-size: 18px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    .user {
        flex-direction: column;
        margin: auto;

        .profile, .credits {
            margin: 0.3rem auto;
            width: fit-content;
        }
    }

    .logout {
        background-color: $button;
        margin: 0.5rem 0;
    }

    &.open {
        height: 340px;
        pointer-events: initial;

        button {
            opacity: 1;
            transition: opacity 0.15s 0.2s ease-in-out;
        }
    }
}