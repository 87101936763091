
@import "./colors.scss";
@import "./constants.scss";

.user {
    display: flex;
    margin: auto 1rem auto auto;
    background-color: $header-color;
    
    .avatar {
        width: 40px;
        height: 40px;

        .lazy-load-image, img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            min-width: 40px;
            min-height: 40px;
        }

        p {
            font-size: 18px;
        }
    }

    .credits {
        margin: auto 0;
        color: $white;
        font-weight: bold;
        padding: 0 1rem 0 0.5rem;
        cursor: pointer;

        &:hover {
            filter: brightness(0.95);
        }
    }

    button {
        margin: auto 0;

        &.login {
            margin-right: 0.5rem;
        }

        &.profile {
            display: flex;
            padding: 0.3rem 0;
            background-color: inherit;
            max-width: 200px;

            h4 {
                color: $white;
                font-size: 0.9rem;
                margin: auto 0.5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
                overflow-x: hidden;
            }
        }
    }

    &.mobile {
        margin: 0;
        justify-content: space-between;
        padding: 0 1rem;

        &.anonymous {
            justify-content: flex-end;
            padding: 0.5rem 1rem;
        }

        button.logout {
            display: none;
        }
    }
}

.profile {
    position: relative;

    .notification-symbol {
        position: absolute;
        left: 26px;
        top: -1px;
        background-color: #B82A31;
        padding: 0.3rem 0.5rem;
        border-radius: 20px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
        z-index: 10000;

        span {
            font-weight: bold;
            color: #fff;
        }
    }
}

.page.profile {
    width: 100%;
    flex-wrap: wrap;

    .containers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        height: fit-content;
        margin: 0 auto;
        width: 100%;

        .column {
            width: calc(100% - 2rem);
            max-width: 800px;
        }
    }
    
    h2 {
        text-align: center;
        color: $button;
        margin: 1rem 0;
    }

    .settings, .credits, .payment, .histories, .notifications, .posts {
        margin: 2rem auto;
        padding: 1rem;
        border-radius: 5px;
        background-color: darken(#fff, 5);
        height: fit-content;

        .post {
            background-color: darken(#fff, 2);
        }

        @media screen and (max-width: 690px) {
            margin: 2rem 0.5rem;
        }
    }

    @media screen and (max-width: $mobile-view) {
        flex-direction: column;

        .settings, .credits, .payment {
            margin: 1rem auto;
        }
    }

    .settings {
        width: calc(95% - 2rem);
        max-width: 800px;

        .image {
            margin: auto 0.5rem auto 0;

            label {
                display: inline-block;
                font-weight: bold;
                margin: 0.5rem auto;
            }

            .outer-wrapper {
                margin: 0 auto;
            }
        }

        .columns {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 1090px) {
                flex-direction: column;

                form {
                    margin: 1rem auto;
                }
            }
        }

        .referral {
            display: flex;
            flex-direction: column;
            margin: 1rem 0;

            h3 {
                color: $button;
                text-align: center;
                margin: 0 auto;
            }

            .copy-box {
                display: flex;

                input {
                    width: 100%;
                    margin: auto 0;
                }

                button {
                    display: flex;
                    padding: 0.3rem 0.5rem;
                    margin: auto 1rem;

                    span {
                        color: #fff;
                        margin: auto 0.5rem;
                        font-size: 14px;
                    }

                    svg {
                        fill: #fff;
                        width: 28px;
                        height: 28px;
                    }
                }
            }
            
            .downloadable-image {
                position: relative;
                width: 100%;
                max-width: 350px;
                min-height: 250px;
                margin: 1rem auto 0 auto;

                .lazy-load-image {
                    position: relative;
                    border-radius: 5px;
    
                    img {
                        max-width: 100%;
                        border-radius: 5px;
                    }
                }
    
                svg.download {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 50%;
                    height: 50%;
                    fill: rgb(255, 255, 255, 0);
                    z-index: 1000;
                    transition: fill 0.2s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    svg.download {
                        fill: rgb(255, 255, 255, 1);
                    }
                }

                @media screen and (max-width: 750px) {
                    svg.download {
                        fill: rgb(255, 255, 255, 0.6);
                    }
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 400px;

            .setting {
                position: relative;
                display: flex;
                justify-content: space-between;
                max-width: 400px;

                &.username, &.gender {
                    width: 324px;
                }

                label {
                    font-weight: bold;
                    margin: auto 0;
                }

                .right {
                    display: flex;
                    width: 253px;
                }

                select {
                    padding: 0.5vh 0;
                }

                button {
                    padding: 0.2rem;
                    height: 30px;
                    margin: auto 0 auto 0.2rem;
                }

                &.date {
                    button {
                        display: flex;
                        background-color: $error;
                        margin: auto 0.2rem;
                    }

                    .right {
                        margin-right: 6px;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: $white;
                        margin: auto;
                    }
                }

                @media screen and (max-width: 470px) {
                    flex-direction: column;
                    width: 100% !important;
                    margin: 0.25rem auto;

                    .right {
                        width: 100% !important;
                        margin: auto;

                        input {
                            width: 100%;
                        }
                    }
                }
            }

            input[type=submit] {
                margin: 1rem auto 0 auto;
                width: 180px;
            }
        }
    }

    .credits {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 0 0 300px;
        max-width: 400px;

        h3 {
            margin: 1rem auto 0rem auto;
        }

        button {
            width: 150px;
            margin: 0.2rem auto;
        }

        .payment-methods {
            display: flex;
            margin: 1rem auto;
            background-color: darken(#fff, 12);
            padding: 0.5rem 0.8rem;
            border-radius: 5px;

            img {
                width: 64px;
                height: 64px;
                margin: 0 0.5rem;
            }
        }
    }

    .payment {
        margin: 0 auto auto auto;
    }

    .histories {
        display: flex;
        flex-direction: column;
        max-width: 680px;

        @media screen and (max-width: 690px) {
            padding: 1rem 0.3rem;

            h3 {
                text-align: center;
            }
        }

        .header {
            display: flex;

            .consultant, .user {
                width: 198px;
                margin: 0.5rem 0;
                padding: 0 1rem;
                background-color: inherit;
            }

            .date {
                width: 125px;
                margin: 0.5rem 0 0.5rem 1rem;
            }

            .time {
                width: 50px;
                margin: 0.5rem 0;
            }

            @media screen and (max-width: 690px) {
                justify-content: space-between;

                .consultant, .date {
                    width: fit-content;
                    padding: 0 0.2rem;
                }
            }
        }

        .list {
            display: flex;
            flex-direction: column;

            .loading {
                margin: auto;
            }    
        }

        .chat-history {
            background-color: darken(#fff, 12);

            &:nth-child(odd) {
                background-color: darken(#fff, 4);
            }

            &:hover {
                filter: brightness(0.95);
                cursor: pointer;
            }
        }

        .chat-history .content {
            display: flex;
            flex-direction: row;
            padding: 0.5rem 1rem;

            .left {
                margin-right: 1rem;
            }

            .left, .right {
                display: flex;

                .avatar, .lazy-load-image, img {
                    width: 48px;
                    height: 48px;
                    min-width: 48px;
                    min-height: 48px;

                    p {
                        font-size: 20px;
                    }
                }

                span {
                    margin: auto 0.5rem;
                    width: 150px;
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }

            .info {
                display: flex;

                span {
                    font-weight: bold;
                    margin: auto 1rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .date {
                    width: 100px;
                }

                .time {
                    width: 50px;
                }
            }

            @media screen and (max-width: 690px) {
                display: block;
                padding: 0.3rem 0.5rem;

                .left, .right {
                    width: 100%;

                    span {
                        width: 100%;

                        &.username {
                            max-width: 50%;
                        }
                    }

                    .date, .mins {
                        margin: auto 0 auto auto;
                        width: fit-content;
                    }
                }

                .left {
                    margin-bottom: 0.3rem;
                }
            }
        }
    }

    .notifications {
        width: 400px;

        .list {
            .notification {
                background-color: darken(#fff, 12);

                &:nth-child(odd) {
                    background-color: darken(#fff, 4);
                }

                &:hover {
                    filter: brightness(0.95);
                    cursor: pointer;
                }
            }

            .notification .content {
                display: flex;
                padding: 0.5rem 0.8rem;
                
                .type {
                    display: flex;
                    width: 38px;
                    height: 38px;
                    margin: auto 0.7rem auto 0.2rem;
                    background-color: $button;
                    border-radius: 50px;

                    svg {
                        width: 24px;
                        height: 24px;
                        margin: auto;
                        fill: #fff;
                    }
                }

                .left {
                    display: flex;

                    .avatar, .lazy-load-image, img {
                        width: 48px;
                        height: 48px;
                        min-width: 48px;
                        min-height: 48px;

                        p {
                            font-size: 20px;
                        }
                    }

                    .username {
                        font-weight: bold;
                        margin: auto 1rem;
                    }
                }

                .info {
                    display: flex;
                    margin-left: auto;

                    .time {
                        font-weight: bold;
                        margin: auto 0.5rem;
                    }
                }
            }
        }
    }

    .posts {
        width: 100%;
        max-width: 1000px;

        h2 {
            margin: 0 auto;
        }
    }
}