@import '../scss/colors.scss';

.rens-tip {
	display: inline-block;
}

#rensAlert {
	position: fixed;
	display: flex;
	flex-direction: column-reverse;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	align-items: flex-end;
	justify-content: flex-start;
	top: 0;
	z-index: 10000;

	.popup,
	.confirm,
	.accept,
	.input,
	.tooltip {
		display: flex;
		position: fixed;
		flex-direction: column;
		text-align: center;
		background-color: darken($white, 2);
		width: 100%;
		max-width: 550px;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
		z-index: 10000;
		border-radius: 5px;
		pointer-events: initial;

		h3 {
			color: $indian-red;
		}

		.top {
			position: absolute;
			display: flex;
			justify-content: flex-end;
			width: 100%;

			button {
				background-color: $error;
				border: none;
				right: 5px;
				top: 5px;
				width: 25px;
				height: 25px;
				margin: 5px 5px 0 0;
				color: #FFF;
				cursor: pointer;
				transition: .3s background;
				padding: 0px;
				font-size: 14px;
				border-radius: 3px;

				&:hover {
					background: darken($error, 8);
				}
			}
		}

		.content {
			margin: 3% auto 5% auto;
			width: 90%;

			input {
				margin-top: 5%;
				padding: 4px 0;
			}

			p {
				margin-bottom: 20%;
				width: 100%;
				font-size: 20px;
				padding: 1rem 1.5rem;
			}
		}

		.buttons {
			button {
				border-radius: 5px;
				border: none;
				color: #FFF;
				padding: 0.2vh 1vw;
				margin: 0.2vh 0.2vw 1vh 0.2vw;
			}

			.acceptButton {
				background-color: #040703;
			}

			.declineButton {
				background-color: #D30E0E;
			}
		}
	}

	.tooltip {
		.content {
			width: 100%;
		}
	}
}
