@import "./colors.scss";

.popup.error, .popup.oops, .popup.update, .confirm {
    background-color: darken(#fff, 6) !important;
    width: 95% !important;

    .top button {
        z-index: 10;
    }    

    .content {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        width: 100% !important;

        .pop {
            position: relative;
            display: flex;
            border-radius: 5px;
    
            p {
                margin: auto !important;
                height: fit-content;
            }

            .right {
                display: flex;
                flex-direction: column;
                
                h3 {
                    font-size: 1.8rem;
                    margin: 0.5rem 0;
                }
        
                p {
                    margin: 0 auto !important;
                }
            }   
        }

        .buttons {
            margin: 1rem auto 0;

            button {
                padding: 0.5rem 2rem !important;
                margin: auto 0.5rem;

                &.acceptButton {
                    background-color: $good !important;
                }

                &.declineButton {
                    background-color: $error !important;
                }
            }
        }

        .left {
            background-color: $error;
            padding: 1rem;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            svg {
                width: 100px;
                height: 100px;
                fill: $white;
                margin: auto;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        .content .pop {
            .left {
                svg {
                    width: 40px;
                    height: 40px;
                }
            }

            p {
                font-size: 1.1rem !important;
            }
        }
    }
}

.popup.update {
    .left {
        background-color: $good !important;
    }

    .content p {
        font-size: 1.1rem !important;
    }
}

.popup.notify {
    .notify p {
        padding: 1rem 0 0 0 !important;
        margin-bottom: 0 !important;
    }
}

.popup.oops {
    .left {
        background-color: orange !important;
    }
}

#rensAlert .popup {
    width: 98%;
    max-width: 650px;

    .content {
        display: flex;
        margin: 0 !important;
        width: 95%;

        .popup-success {
            display: flex;

            .left {
                display: flex;
                background-color: $caribbean-green !important;
                width: 140px;
                padding: 1rem;
                margin: 0 1rem 0 0;
        
                svg {
                    fill: #fff;
                    margin: auto;
                }
            }

            p {
                width: calc(100% - 140px);
                margin: 1rem 0;
                padding: 1rem 0;
            }
        }
    }
}