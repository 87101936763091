
@import "./colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a, p, span, button {
    color: darkslategray;
}

input {
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid $slate-gray;
    margin: 0.5vh 0;
}

.app, #root {
    background-color: $white;
    min-height: 100vh;
}

.app {
    display: flex;
    flex-direction: column;
}

.page {
    display: flex;
    min-height: 100%;
    width: 100%;
}

.page:not(.home) {
    background-image: url("../images/background.webp");
    background-repeat: repeat-y;
    background-size: 100% auto;
}

.loading {
    display: flex;
    margin: auto;

    span {
        color: $button;
        font-weight: bold;
        margin: auto 0;
    }

    .rcs-wave {
        margin: auto 0;
    }
}

.tool-tip {
    display: none;
    position: absolute;
    border-radius: 4px;
    top: -1rem;
    width: 100vw;
    max-width: 315px;
    text-align: center;
    padding: 1rem;
    background-color: darken(#fff, 15);
    cursor: pointer;
}

.empty {
    text-align: center;
    width: 100%;
    margin: 1rem auto;
    font-weight: bold;
}

.spacer {
    margin: auto 0.3rem;
}

.text-color-red {
    color: red;
    padding-right: 0.2rem;
}

.page {
    min-height: calc(100vh - 55px - #{$footer-height});

    @media screen and (max-width: $mobile-header-width) {
        min-height: calc(100vh - 105px - #{$footer-height});
    }
}

.about, .explanation, .contact, .spiritual-coach, .spiritual-center, .clairvoyant-online {
    .content {
        display: flex;
        flex-direction: column;
        width: 98%;
        background-color: #e2e2e2;
        max-width: 1250px;
        margin: 1rem auto 1rem auto;
        border-radius: 5px;
        padding: 1rem 0 2rem 0;

        h1 {
            width: 95%;
            max-width: 1000px;
            margin: 1rem auto;
        }

        p, h2, h3, h4, ul {
            width: 95%;
            max-width: 1000px;
            margin: auto;
            color: #000;
        }

        h2 {
            margin: 1rem auto;
        }

        h3 {
            margin: 1rem auto;
        }

        h4 {
            margin: 1rem auto;
        }

        ul li h3 {
            margin: 0 auto;
        }

        img {
            margin: 2rem auto 0 auto;
        }

        .extra-margin {
            margin: 1rem auto;
        }

        .email-link {
            display: flex;
            margin: 1rem auto;
            width: 95%;
            max-width: 1000px;

            h3 {
                margin: auto 1rem auto 0;
                width: fit-content;
            }

            a {
                margin: auto 0;
                font-weight: bold;
                font-size: 1.1rem;

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }
    }
}

.background {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
}

.username {
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
}

.paginator {
    display: flex;
    margin: 1vh auto;
    justify-content: center;

    .next,
    .prev {
        padding: 0.5rem;
    }

    .page-numbers {
        display: flex;
        justify-content: center;
        font-weight: bold;
        width: 250px;
        color: $white;
        cursor: pointer;

        .number,
        .active {
            margin: auto 0.5rem;
        }

        .active {
            text-decoration: underline;
        }
    }
}

.mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(10, 10, 10, 0.75);
    top: 0;
    left: 0;
    z-index: 500;
}

.image-viewer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    height: fit-content;
    max-height: calc(98% - 6rem);
    max-width: calc(95% - 2rem);
    padding: 3rem 1rem;
    background-color: lighten($slate-gray, 20);

    .lazy-load-image {
        width: fit-content;
        height: fit-content;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

@keyframes hideButtonAnim {
    0%      { background-color: $button;  }
    50%     { background-color: orange; }
    100%    { background-color: $button;  }
}

.consultant-state {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 6000;
    bottom: 0;
    right: 0;
    width: 400px;
    padding: 0.5rem;
    border-top-left-radius: 5px;
    background-color: darken(#fff, 15);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.6);
    
    h3 {
        color: $button;
        margin: auto auto 0.5rem auto;
    }

    h4 {
        text-align: center;
        color: $button;
    }

    .phone {
        display: flex;
        margin: 0.2rem auto 1rem auto;

        span {
            margin: auto 1rem;
            font-weight: bold;
        }

        .slider {
            background-color: darken(#fff, 26);
            border-radius: 3px;
            width: 80px;
            height: 2rem;
        }

        .thumb {
            background-color: gray;
            border-radius: 3px;
            width: 40px;
            height: 2rem;
            margin: 0;
            transform: translateX(0px);
            transition: transform 0.1s ease-in-out;
            cursor: pointer;

            &.online {
                background-color: $caribbean-green;
                transform: translateX(40px);
            }

            &:hover {
                filter: brightness(0.97);
            }
        }
    }

    .buttons {
        display: flex;
        margin: 0 auto 1rem auto;

        button {
            background-color: $good;
            margin: auto 0.3rem;
    
            &.offline {
                background-color: $error;
            }

            &.pause {
                background-color: orange;
            }

            &:disabled {
                background-color: gray;
            }
        }
    }

    .invites {
        display: flex;
        flex-direction: column;
        background-color: darken(#fff, 20);
        border-radius: 5px;
        padding-bottom: 1rem;

        h3 {
            text-align: center;
        }

        .empty {
            font-weight: bold;
            margin: auto;
        }

        .invite {
            display: flex;
            background-color: darken(#fff, 23);
            padding: 0.5rem 0;
            width: 95%;
            margin: auto;

            &:nth-child(odd) {
                background-color: darken(#fff, 28);
            }

            p {
                margin: auto 0 auto 0.5rem;
                font-weight: bold;
                font-size: 1.1rem;
            }

            .buttons {
                margin: auto 0 auto auto;

                button {
                    width: fit-content;
                    padding: 0.4rem 0.6rem;

                    &.decline {
                        background-color: $error;
                    }
                }
            }
        }
    }

    .hide {
        position: absolute;
        top: 5px;
        right: 5px;

        button.expand {
            display: flex;
            width: 28px;
            height: 28px;
            padding: 0;
            background-color: $button;

            &.animate {
                animation: hideButtonAnim 1.2s ease-in-out infinite;
            }

            svg {
                width: 20px;
                height: 20px;
                margin: auto;
                fill: $white;
                transition: transform 0.3s ease-in-out;
            }
        }
    }

    &.hidden {
        height: 20px;
        width: 230px;

        .hide svg {
            transform: rotate(180deg);
        }
    }
}

button, input[type=submit] {
    border: none;
    border-radius: 5px;
    padding: 0.6rem 1.4rem;
    font-weight: bold;
    background-color: $button;
    color: $white;

    &:hover {
        cursor: pointer;
        filter: brightness(0.95);
    }

    &:disabled {
        background-color: gray !important;
        cursor: context-menu;

        &:hover {
            filter: none;
        }
    }
}

button.add, button.save {
    display: flex;
    background-color: $good;
    width: fit-content;
    height: 32px;
    padding: 0;

    span {
        margin: auto 0.5rem auto 0;
        color: #fff;
    }

    svg {
        width: 24px;
        height: 24px;
        fill: #fff;
        margin: auto 0.5rem;
    }

    &:disabled {
        background-color: gray;
        cursor: context-menu;

        &:hover {
            filter: none;
        }
    }
}

button.close, button.delete {
    position: absolute;
    display: flex;
    background-color: $error;
    width: 32px;
    height: 32px;
    padding: 0;
    top: 0.5rem;
    right: 0.5rem;

    svg {
        fill: $white;
        width: 24px;
        height: 24px;
        margin: auto;
    }
}

button.edit {
    position: absolute;
    display: flex;
    background-color: orange;
    width: 32px;
    height: 32px;
    padding: 0;
    top: 0;
    right: 1rem;

    svg {
        fill: $white;
        width: 20px;
        height: 20px;
        margin: auto;
    }
}

ul {
    margin: 1rem auto;
    list-style-type: disc;

    li {
        margin: 0.3rem 2rem;
    }
}

.confirm p {
    max-width: calc(100% - 3rem) !important;
}

.popup.email, .popup.password, .popup.forgotPass {
    position: fixed;
    background-color: darken(#fff, 6);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem;
    border-radius: 5px;
    width: 95%;
    max-width: 450px;

    h2 {
        text-align: center;
        color: $button;
        margin: 0.5rem 0;
    }

    form {
        display: flex;
        flex-direction: column;

        input {
            width: 100%;
            max-width: 350px;
            margin: 0.5rem auto;
        }

        input[type=submit] {
            max-width: 180px;
            margin: 1rem auto;
        }
    }
}

#rensAlert .popup.notify {
    width: 98% !important;
    padding-bottom: 1rem;
    background-color: darken(#fff, 3);

    .content {
        margin: 0 !important;

        .notify {
            width: 95%;
        }
    }
}

.popup.forgotPass {
    form input[type=submit] {
        max-width: 250px;
    }
}

.avatar {
    position: relative;
    display: flex;
    background-color: inherit;
    width: 128px;
    height: 128px;
    border-radius: 32px;

    button.edit {
        display: flex;
        top: 5px;
        right: 5px;
    }

    p {
        margin: auto;
        font-weight: bold;
        font-size: 40px;
        color: $white;
    }

    .image {
        display: flex;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 32px;
        margin: auto 18px;
    }

    img {
        width: 128px;
        height: 128px;
        border-radius: 32px;
    }
}

.outer-wrapper {
    position: relative;
    width: fit-content;
    margin: 1vh auto;

    .delete.image {
        display: flex;
        top: 0.5rem;
        right: 0.5rem;
        position: absolute;
        width: 32px;
        height: 32px;
        background-color: $error;
        border-radius: 5px;
        padding: 0;
        z-index: 100;

        svg {
            margin: auto;
            width: 24px;
            height: 24px;
            fill: $white;
        }
    }

    .dropzone {
        position: relative;
        display: flex;
        background-color: darken(#fff, 8);
        border: 2px dashed lighten($button, 10);
        border-radius: 5px;
        height: 200px;
        width: 200px;

        &:hover {
            filter: brightness(0.98);
            cursor: pointer;
        }

        p {
            position: absolute;
            text-align: center;
            font-weight: bold;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            color: #000;
        }

        img {
            display: none;
            margin: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }

        &.preview {
            p {
                display: none;
            }

            img {
                display: block;
            }

            &:hover p {
                display: block;
            }
        }

    }
}

.lazy-load-image {
    display: flex;
    background-color: darken(#fff, 10);
    border-radius: 32px;
    min-width: 128px;
    min-height: 128px;

    .rcs-ring.spinner {
        margin: auto;
    }    

    .error {
        font-weight: bold;
        text-align: center;
        margin: auto !important;
    }
}