
@import "./colors.scss";

footer {
    position: relative;
    display: flex;
    background-color: $footer;
    height: #{$footer-height};
   
    nav {
        display: flex;
        flex-direction: column;
        margin: auto 0;

        ul {
            list-style: none;
            margin: 0;

            li {
                color: $white;
                font-weight: bold;
                font-size: 1.12rem;
                cursor: pointer;

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }
    }

    .center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;

        .copy {
            color: $white;
            font-weight: bold;
            margin: auto;
        }
    
        .powered-by {
            color: $white;
            font-weight: bold;

            a {
                color: $purple;

                &:hover {
                    color: darken($purple, 10);
                }
            }
        }

        .attribution {
            font-size: 0.7rem;
            margin: 0.8vh auto 0 auto;
            color: $white;
    
            a {
                color: $purple;
                text-decoration: none;
            }
        }
    }
}