@import "./constants.scss";
@import "./colors.scss";

.page.auth {
    h2 {
        color: $button;
        text-align: center;
        margin-bottom: 2vh;
    }

    .center {
        display: flex;
        width: 95%;
        max-width: 1300px;
        margin: auto;
        background-color: $white;
        height: 100vh;
        max-height: 500px;
        border-radius: 5px;

        .divider {
            background-color: $button;
            flex: 0 0 2px;
            height: 250px;
            margin: auto 0;
        }

        input.register,
        input.login {
            margin: 1vh auto;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 600px;
            margin: auto;

            input {
                max-width: 350px;
                width: calc(95% - 1rem);
                margin: 0.5vh auto;
            }

            .controls {
                display: flex;
                flex-direction: column;
                padding: 1vh 0;
                color: $white;

                .top {
                    display: flex;
                    margin: auto;
                    justify-content: center;
                    width: 100%;

                    button {
                        margin: auto 2%;
                    }

                    .remember {
                        display: flex;
                        justify-content: center;
                        margin: auto 2%;
                        color: $slate-gray;

                        input {
                            width: 24px;
                            margin: auto 0;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mobile-view) {
        .center {
            flex-direction: column;
            height: 80vh;
            max-height: 800px;

            .divider {
                width: 90%;
                max-width: 450px;
                height: 2px;
                margin: 2vh auto;
            }
        }
    }
}

.page.verify {
    display: flex;

    .content {
        width: 100%;
        max-width: 1000px;
        margin: auto;
        text-align: center;
        margin-top: 10vh;

        .verifyText,
        .verifiedText {
            display: flex;
            justify-content: center;
            color: $white;
            background-color: darken(#fff, 12);
            width: fit-content;
            padding: 2rem;
            border-radius: 5px;
            margin: auto;

            h2 {
                margin: auto 1vw;
                color: #000;
            }

            button {
                font-weight: bold;
                max-width: 250px;
                margin: 2rem auto 0 auto;
                padding: 0.8rem 1.5rem;
                background-color: $button;
                color: $white;
            }
        }

        .verifiedText {
            flex-direction: column;
        }
    }
}

.page.reset-password {
    .content {
        width: 100%;
        max-width: 600px;
        margin: 5vh auto auto auto;
        text-align: center;
        color: $button;
        background-color: darken(#fff, 6);
        border-radius: 5px;
        padding: 1rem;

        form {
            display: flex;
            flex-direction: column;

            input {
                max-width: 350px;
                margin: 0.5rem auto;
                width: 100%;

                &[type=submit] {
                    max-width: 200px;
                }
            }
        }
    }
}
